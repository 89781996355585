<template>
  <ChangePasswordWrapper>
    <cds-cards headless class="bg-ems-gray800 py-4 min-h-740px rounded-none">
      <div class="uppercase text-white ml-20px font-semibold">{{$t('common.change_your_password')}}</div>
      <div class="border-b-1px border-ems-gray700 my-4 mx-4"></div>
      <a-row type="flex" justify="center" class="py-100px">
        <a-col :lg="8" :sm="20" :xs="24">
          <BasicFormWrapper>
            <a-form :model="formState" ref="formRef" layout="vertical" :rules="rules" :validate-trigger="['change', 'blur']">
              <a-form-item name="oldPassword" :label="$t('common.old_password')">
                <a-input-password class="ant-input-sm max-w-332px" v-model:value="formState.oldPassword" :maxlength="32"/>
              </a-form-item>
              <a-form-item name="newPassword" :label="$t('common.new_password')">
                <a-input-password class="ant-input-sm max-w-332px" v-model:value="formState.newPassword" :maxlength="32"/>
              </a-form-item>
            </a-form>
          </BasicFormWrapper>
        </a-col>
      </a-row>
    </cds-cards>
  </ChangePasswordWrapper>
  <showConfirmModal ref="showConfirm" :closable="false" :width="'450px'"></showConfirmModal>
</template>

<script>
import { ChangePasswordWrapper } from './style';
import { BasicFormWrapper } from '@/view/styled';
import {defineComponent, inject, onMounted, onUnmounted, reactive, ref} from 'vue';
import { passwordRule,required } from "@/util/validation";
import { useStore } from "vuex";
import {useForm} from "ant-design-vue/es/form";
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';

const Password = defineComponent({
  name: 'Password',
  components: {
    showConfirmModal,
    ChangePasswordWrapper,
    BasicFormWrapper,
  },
  setup() {
    const formState = reactive({
      oldPassword: '',
      newPassword: '',
    });
    const rules = {
      oldPassword: [required('common.old_password')],
      newPassword: [passwordRule(),required('common.new_password')],
    };
    const { dispatch } = useStore();
    const formRef = ref(null)
    const showConfirm = ref(null)
    const { validate } = useForm(formState, rules);
    const eventBus = inject('eventBus');

    onMounted(() => {
      eventBus.on('onSave', () => {
        formRef.value.validate()
        onFinish()
      })
    })

    onUnmounted(() => {
      eventBus.off('onSave')
    })

    const onFinish = async () => {
      validate().then(
          async () => {
            if (await showConfirm.value.onOpenModal('common.save_u_password')) {
              await dispatch('changePassword', formState);
            }
          },
          () => {})
    };


    return {
      formState,
      rules,
      showConfirm,
      formRef,
    };
  },
});

export default Password;
</script>
