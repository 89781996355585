<template>
  <div
      class="flex justify-between bg-ems-gray900 pl-16px pr-24px border-b-1 border-solid border-ems-gray700"
  >
    <div class="pt-38px pb-13px">
      <div class="text-ems-white font-semibold text-16px uppercase">
        {{ $t('common.user_info') }}
      </div>
    </div>
    <div class="flex flex-col justify-center">
      <ClipButton class="flex items-center" :type="'primary'" @click="eventBus.emit('onSave')">
        <span>{{ $t('common.save') }}</span>
      </ClipButton>
    </div>
  </div>
  <div class="pt-4">
    <cds-main>
      <a-row :gutter="25">
        <a-col :xxl="6" :lg="8" :md="10" :xs="24">
          <ProfileAuthorBox>
            <author-box />
          </ProfileAuthorBox>
        </a-col>
        <a-col :xxl="18" :lg="16" :md="14" :xs="24">
          <SettingWrapper>
<!--            <CoverSection />-->
            <Suspense>
              <template #default>
                <router-view></router-view>
              </template>
              <template #fallback>
                <div class="spin">
                  <a-spin />
                </div>
              </template>
            </Suspense>
          </SettingWrapper>
        </a-col>
      </a-row>
    </cds-main>
  </div>
</template>

<script>
import { ProfileAuthorBox, SettingWrapper } from './overview/style';
import AuthorBox from './overview/ProfileAuthorBox';
import {defineComponent, inject} from 'vue';
import ClipButton from "@/components/buttons/ClipButton.vue";

export default defineComponent({
  name: 'Settings',
  components: {
    ClipButton,
    SettingWrapper,
    AuthorBox,
    ProfileAuthorBox,
  },
  setup() {
    const eventBus = inject('eventBus');
    return {
      eventBus
    }
  }
});
</script>
