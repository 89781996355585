<template>
  <a-spin size="large" :spinning="loading">
    <cds-cards headless class="bg-ems-gray800 rounded-none py-4 min-h-740px">
      <div class="uppercase text-white ml-20px font-semibold">
        {{ $t('common.update_info_personal') }}
      </div>
      <div class="border-b-1px border-ems-gray700 my-4 mx-4"></div>
      <a-row type="flex" justify="center" class="py-3">
        <a-col :xl="16" :lg="16" :xs="24">
          <a-form
            ref="formModal"
            :model="formState"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label-align="left"
            :rules="rules"
            :validate-trigger="['change', 'blur']"
            @finish="onFinish"
            layout="vertical"
          >
            <div class="grid grid-cols-2 gap-4 xl:grid-cols-1 2xl:grid-cols-2">
              <a-form-item :label="$t('user.username')" name="username">
                <cds-input
                  class="w-100 ant-input-sm"
                  v-model:value="formState.username"
                  :maxlength="64"
                  show-count
                  disabled
                  :placeholder="$t('user.enter_officer_code')"
                />
              </a-form-item>
              <a-form-item :label="$t('user.full_name')" name="fullName">
                <cds-input
                  class="w-100 ant-input-sm"
                  v-model:value="formState.fullName"
                  :maxlength="50"
                  show-count
                  :placeholder="$t('user.full_name')"
                />
              </a-form-item>
              <a-form-item :label="$t('user.subsystem')" name="clientId">
                <cds-select
                  :options="SUBSYSTEM"
                  :placeholder="$t('user.subsystem')"
                  label-prop="name"
                  value-prop="id"
                  :disabled="true"
                  v-model:value="formState.clientId"
                />
              </a-form-item>
              <a-form-item
                :label="$t('user_group.system_user_group')"
                name="valueUserRoles"
              >
                <a-popover placement="top">
                  <a-select
                    v-model:value="formState.valueUserRoles"
                    mode="multiple"
                    :max-tag-count="2"
                    style="width: 100%"
                    :placeholder="$t('user_group.system_user_group')"
                    :options="tree"
                    :disabled="true"
                    :show-arrow="true"
                    :max-tag-text-length="18"
                    :filter-option="filterOption"
                    class="text-white inline-block"
                  >
                    <template v-slot:tagRender="{ label }">
                      <a-tag
                        :closable="false"
                        class="bg-ems-gray500 text-white font-normal flex justify-center border-ems-gray400 mt-2"
                        style="cursor: pointer"
                      >
                        {{ label }}&nbsp;&nbsp;
                      </a-tag>
                    </template>
                  </a-select>
                  <template #content>
                    <span
                      class="inline-block rounded-[4px] text-ems-gray200 text-sm px-3 mr-2 max-w-[700px]"
                      :key="idx"
                    >
                      {{ valueGroup }}
                    </span>
                  </template>
                  <span
                    class="inline-block rounded-[4px] px-1 bg-ems-gray600 ml-2 absolute top-1 right-10 h-[25px] w-[80px] opacity-0"
                    v-if="formState.valueUserRoles.length > 0"
                  >
                  </span>
                </a-popover>
              </a-form-item>
              <a-form-item :label="$t('user.status')" name="status">
                <cds-select
                  disabled
                  :options="status"
                  :placeholder="$t('user.status')"
                  v-model:value="formState.status"
                />
              </a-form-item>
              <a-form-item :label="$t('user.position')" name="position">
                <cds-input
                  class="w-100"
                  v-model:value="formState.position"
                  :maxlength="255"
                  :disabled="readOnly"
                  :placeholder="$t('user.position')"
                />
              </a-form-item>
              <a-form-item :label="$t('user.sex')" name="gender">
                <cds-select
                  :options="genders"
                  :placeholder="$t('user.sex')"
                  v-model:value="formState.gender"
                />
              </a-form-item>
              <a-form-item :label="$t('role-group.email')" name="email">
                <cds-input
                  class="w-100"
                  v-model:value="formState.email"
                  :maxlength="255"
                  :disabled="readOnly"
                  :placeholder="$t('role-group.email')"
                />
              </a-form-item>
              <a-form-item :label="$t('user.phone')" name="phone">
                <cds-input
                  class="w-100 ant-input-sm"
                  v-model:value="formState.phone"
                  :maxlength="10"
                  show-count
                  size="large"
                  :placeholder="$t('user.phone')"
                />
              </a-form-item>
            </div>
          </a-form>
        </a-col>
      </a-row>
    </cds-cards>
  </a-spin>

  <showConfirmModal
    ref="showConfirm"
    :closable="false"
    :width="'450px'"
  ></showConfirmModal>
</template>
<script>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from 'vue';
import { useStore } from 'vuex';
import { emailRule, maxlength, patternRule, required } from '@/util/validation';
import { FORM_MODE, GENDERS, STATUS } from '@/util/common-constant';
import { greaterThanCurrent } from '@/util/common-utils';
import { useI18n } from 'vue-i18n';
import { useForm } from 'ant-design-vue/es/form';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import { SUBSYSTEM } from '@/config/Constant';

const Profile = defineComponent({
  name: 'Profile',
  components: { showConfirmModal },
  setup() {
    const { t, locale } = useI18n();
    const { state, dispatch } = useStore();
    const formState = reactive({
      ...state.auth.userInfo,
      valueUserRoles: state.auth.userInfo.userRoles.map((e) => e.roleId),
      userRoles: null,
    });
    const formMode = computed(() => state.user.formMode);
    const roles = computed(() => state.role.list);
    const loading = computed(() => state.auth.loading);
    const rules = {
      // description: [maxlength(100, 'Mô tả')],
      phone: [
        maxlength(10, t('user.phone')),
        patternRule(/(0)+([0-9]{9})/, t('user.phone')),
      ],
      // gender: [required('Gender')],
      // password: [required('Password')],
      fullName: [
        required(t('user.full_name')),
        maxlength(64, t('user.full_name')),
      ],
      username: [
        required(t('user.username')),
        maxlength(50, t('user.username')),
      ],
      // address: [maxlength(250, 'Address')],
      clientId: [required(t('user.subsystem'))],
      valueUserRoles: [required(t('user_group.system_user_group'))],
      email: [
        emailRule(t('role-group.email')),
        maxlength(255, t('role-group.email')),
      ],
    };
    // const labelCol = {
    //   lg: 8,
    //   md: 8,
    //   xs: 24,
    // };
    // const wrapperCol = {
    //   lg: 16,
    //   md: 16,
    //   xs: 24,
    // };
    const formModal = ref(null);
    const fieldNames = { label: 'name', key: 'value' };
    const disabled = formMode.value === FORM_MODE.VIEW;
    const eventBus = inject('eventBus');
    const { validate } = useForm(formState, rules);
    const showConfirm = ref(null);
    const tree = computed(() => {
      if (!state.role.list || state.role.list.length === 0) {
        return [];
      }
      return state.role.list.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    });
    const valueGroup = computed(() =>
      tree.value
        .filter((e) => formState.valueUserRoles.includes(e.value))
        .map((e) => e.label.trim())
        .join(', ')
    );

    onMounted(() => {
      dispatch('userGroup/getAll');
      dispatch('role/getAll');
      eventBus.on('onSave', () => {
        onFinish();
      });
    });

    onUnmounted(() => {
      eventBus.off('onSave');
    });

    const onFinish = async () => {
      validate().then(
        async () => {
          if (await showConfirm.value.onOpenModal('common.save_u_info')) {
            await dispatch('updateProfile', { ...formState });
          }
        },
        () => {}
      );
    };

    const filterOption = (input, option) => {
      return (
        option.label
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .indexOf(
            input
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          ) >= 0
      );
    };

    return {
      t,
      locale,
      formState,
      rules,
      // labelCol,
      // wrapperCol,
      fieldNames,
      formModal,
      onFinish,
      loading,
      disabled,
      roles,
      status: STATUS,
      genders: GENDERS,
      formMode,
      FORM_MODE: FORM_MODE,
      disabledToDate: greaterThanCurrent,
      showConfirm,
      tree,
      filterOption,
      SUBSYSTEM,
      valueGroup,
    };
  },
});

export default Profile;
</script>
