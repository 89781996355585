export const TABLE_LINE_CHART = {
  LAND: 'LAND',
  RECRUITMENT: 'RECRUITMENT',
  CV: 'CV',
  CLASSIFIEDS: 'CLASSIFIEDS',
};

export const LABEL = {
  YEAR: 'Tháng',
  MONTH: 'Ngày',
};

export const PRE_LABEL = {
  DAY: 'Hôm qua',
  WEEK: 'Tuần trước',
  MONTH: 'Tháng trước',
  YEAR: 'Năm trước',
};

export const DAY_OF_WEEK = [
  'Chủ nhật',
  'Thứ 2',
  'Thứ 3',
  'Thứ 4',
  'Thứ 5',
  'Thứ 6',
  'Thứ 7',
];

export const ROOT_PATH_MINIO = {
  IDENTITY_PERSON: 'person',
  IDENTITY_PERSON_CARD: 'person/card',
  IDENTITY_VEHICLE: 'vehicle',
  IDENTITY_VEHICLE_CARD: 'vehicle/card',
};

export const TYPE_IMAGE = {
  IDENTITY_PERSON_AVATAR: 1,
  IDENTITY_PERSON_FRONT_CARD: 2,
  IDENTITY_PERSON_BACK_CARD: 3,
  IDENTITY_PERSON_VEHICLE: 4,
  IDENTITY_VEHICLE_FRONT_CARD: 5,
  IDENTITY_VEHICLE_BACK_CARD: 6,
};

export const FILTER_APP_PARAMS = {
  CARD_TYPE: 'CardType',
  VEHICLE: 'Vehicle',
  COLOR: 'Color',
  BRAND: 'Brand',
};

export const FILTER_GROUP_TYPE = {
  IO: 1,
  USER: 2,
};

export const FORM_MODE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
};

export const FILTER_SUGGEST_TYPE = {
  DEPARTMENT: 'department',
  POSITION: 'position',
  ORGANIZATION: 'organization',
};

export const TAB_IDENTITY = {
  OBJECT: 'object',
  VEHICLE: 'vehicle',
};

export const RELATIONSHIP_PERSON_VEHICLE_OBJ = {
  REGISTERED: 1,
  RELEVANT: 2,
};

export const RELATIONSHIP_PERSON_VEHICLE_ARR = [
  {
    value: RELATIONSHIP_PERSON_VEHICLE_OBJ.REGISTERED,
    label: 'Registered',
  },
  {
    value: RELATIONSHIP_PERSON_VEHICLE_OBJ.RELEVANT,
    label: 'Relevant',
  },
];
export const STATUS_NOTIFY_REQUEST = {
  REQUEST_PROCESSING: 0,
};
export const ACTION = {
  VIEW: 0,
  EDIT: 1,
  CREATE: 2,
};
export const WARNING_LEVEL = [
  {
    id: 1,
    name: 'KHAN_CAP',
    img: 'priority-KHAN_CAP.png',
    label: 'group-management.KHAN_CAP',
    value: 1,
    color: 'bg-ems-main2',
    textColor: '#E21A3D',
  },
  {
    id: 2,
    name: 'CAO',
    img: 'priority-CAO.png',
    label: 'group-management.CAO',
    value: 2,
    color: 'bg-ems-boTro2_600',
    textColor: '#EA621F',
  },
  {
    id: 3,
    name: 'TRUNG_BINH',
    img: 'priority-TRUNG_BINH.png',
    label: 'group-management.TRUNG_BINH',
    value: 3,
    color: 'bg-ems-boTro4_800',
    textColor: '#008300',
  },
  {
    id: 4,
    name: 'THAP',
    img: 'priority-THAP.png',
    label: 'group-management.THAP',
    value: 4,
    color: 'bg-ems-gray500',
    textColor: '#7B7B7B',
  },
];
export const IO_TYPE = [
  { label: 'group-management.all-type', value: '' },
  {
    label: 'group-management.person',
    value: 1,
  },
  {
    label: 'group-management.vehicle',
    value: 2,
  },
  {
    label: 'group-management.address',
    value: 3,
  },
  {
    label: 'group-management.icon',
    value: 4,
  },
];

export const GENDER_LIST = [
  { value: 0, label: 'object_information.male' },
  { value: 1, label: 'object_information.female' },
  { value: 2, label: 'object_information.undefined' },
];
export const DOCUMENT_TYPE = [
  { value: 1, label: 'object_information.cmt' },
  { value: 2, label: 'object_information.cccd' },
  { value: 3, label: 'object_information.passport' },
  { value: 4, label: 'object_information.driver_license' },
  { value: 0, label: 'object_information.other_document' },
];

export const NATIONALITY_VIETNAM_ID = 115;

export const SUBSYSTEM = [
  {
    id: 1,
    name: 'EMS',
  },
  {
    id: 2,
    name: 'VMS',
  },
  {
    id: 3,
    name: 'APP',
  },
];

export const PHAN_HE = [
  { label: 'menu.phan-he-1', value: 1 },
  { label: 'menu.phan-he-2', value: 2 },
];

export const RELATIONSHIP = {
  family: 'object_information.relatives',
  father: 'object_information.father',
  mother: 'object_information.mother',
  owner: 'object_information.owner',
  sameAddress: 'object_information.sameAddress',
  sameGroup: 'object_information.sameGroup',
  sameOwner: 'object_information.sameOwner',
};

export const RACE = {
  white: 'human.white',
  black: 'human.black',
  latino_hispanic: 'human.latino_hispanic',
  east_asian: 'human.east_asian',
  southeast_asian: 'human.southeast_asian',
  indian: 'human.indian',
  middle_eastern: 'human.middle_eastern',
};
export const BAG = {
  handbag: 'human.handbag',
  shoulderbag: 'human.shoulderbag',
  backpack: 'human.backpack',
};

export const HAIR = {
  short: 'human.short',
  long: 'human.long',
  bald: 'human.bald',
};

export const COLOR = {
  black: 'human.black-color',
  blue: 'human.blue',
  brown: 'human.brown',
  green: 'human.green',
  grey: 'human.grey',
  pink: 'human.pink',
  purple: 'human.purple',
  red: 'human.red',
  white: 'human.white-color',
  yellow: 'human.yellow',
  other: 'human.other',
};
export const STYLE = {
  shortsleeve: 'human.shortsleeve',
  longsleeve: 'human.longsleeve',
  longcoat: 'human.longcoat',
  trousers: 'human.trousers',
  shorts: 'human.shorts',
  skirt_dress: 'human.skirt_dress',
};
export const GENDER = {
  male: 'human.male',
  female: 'human.female',
};

export const EVENT_STATUS = {
  ZONE_INTRUSION: 'identification-event.intrusion',
  ZONE_FLOW_IN: 'identification-event.checkin',
  ZONE_FLOW_OUT: 'in_out_event.checkout',
  UNKNOWN: 'identification-event.appear',
}

export const EVENT_STATUS_COLOR = {
  ZONE_INTRUSION: '#B5122E',
  ZONE_FLOW_IN: '#E30E8D',
  ZONE_FLOW_OUT: '#21A603',
  UNKNOWN: '#EA621F',
}

export const IN_OUT_STATUS = {
  ZONE_FLOW_IN: 'in_out_event.checkin',
  ZONE_FLOW_OUT: 'in_out_event.checkout',
}

export const IN_OUT_STATUS_TEXT = {
  ZONE_FLOW_IN: "ZONE_FLOW_IN",
  ZONE_FLOW_OUT: "ZONE_FLOW_OUT",
}
