<template>
  <cds-modal
      :visible="visible"
      :mask-closable="false"
      hide-footer
      @onCancel="visible = false"
      class="bg-ems-gray800 pb-0"
      :width="width"
      :closable="closable"
  >
    <ModalConfirm
        @confirm="onOk(true)"
        @onClose="onOk(false)"
        :title="title"
    ></ModalConfirm>
  </cds-modal>
</template>
<script setup>
import ModalConfirm from "@/components/modal-confirm/ModalConfirm.vue";
import {ref} from "vue";
import {useI18n} from "vue-i18n";
import VueTypes from "vue-types";

const props = defineProps({
  width: VueTypes.string.def('400px'),
  closable: VueTypes.bool.def(true),
})

const visible = ref(false)

const resolve = ref(undefined)

const title = ref('')
const { t } = useI18n();

const onOk = (bol) => {
  rs(bol)
  visible.value = false
}

const onClose = () => {
  rj(false)
  visible.value = false
}

let rs = undefined
let rj = undefined

const onOpenModal = (mess) => {
  title.value = t(mess)
  visible.value = true
  return new Promise((resolve, reject) => {
    rs = resolve
    rj = reject
  })
}

defineExpose({
  onOpenModal
});
</script>
