<template>
  <cds-cards headless class="bg-ems-gray800 rounded-none min-h-740px">
    <div class="uppercase text-white ml-20px font-semibold">{{$t('common.avatar')}}</div>
    <div class="author-info">
<!--      <figure >-->
      <div class="bg-ems-gray700 rounded-2xl p-4 mt-4">
        <a-spin :spinning="uploading">
          <a-avatar shape="square" :size="240" :src="userInfo.avatar" class="rounded-xl"></a-avatar>
        </a-spin>
        <a-button class="btn-upload" type="primary" shape="circle" @click="$refs.upload.click()">
          <cds-feather-icons type="camera" size="16"/>
        </a-button>
        <input ref="upload" type="file" @change="handleChange" style="display: none">
        <div class="info mt-1 text-center break-words">
          <cds-heading as="h4" class="text-white">{{ userInfo.fullName }}</cds-heading>
        </div>
      </div>
<!--      </figure>-->
<!--        <div class="info">-->
<!--          <cds-heading as="h4" class="text-white">{{ userInfo.fullName }}</cds-heading>-->
          <!--          <p>{{ userInfo.department.name }}</p>-->
<!--        </div>-->
    </div>
<!--    <div class="mt-4 mx-20px">-->
<!--      <div class="flex justify-center bg-ems-gray700 rounded-2xl py-4">-->
<!--        <a-spin :spinning="uploading">-->
<!--          <a-avatar shape="square" :size="{ xs: 200, sm: 200, md: 230, lg: 230, xl: 230, xxl: 287 }" class="rounded-2xl"-->
<!--                    :src="userInfo.avatar" v-if="!uploading">-->
<!--          </a-avatar>-->
<!--          <a-button class="btn-upload absolute top-280px right-128px" type="primary" shape="circle" @click="$refs.upload.click()">-->
<!--            <cds-feather-icons type="camera" size="16"/>-->
<!--          </a-button>-->
<!--          <input ref="upload" type="file" @change="handleChange" style="display: none">-->
<!--          <div class="info mt-4 text-center">-->
<!--            <a-tooltip :title="userInfo.fullName.length > MAX_LENGTH_NAME ? userInfo.fullName : null">-->
<!--              <cds-heading as="h4" class="text-white">{{ userName }}</cds-heading>-->
<!--            </a-tooltip>-->
<!--          </div>-->
<!--        </a-spin>-->
<!--      </div>-->
<!--    </div>-->
    <nav class="settings-menmulist">
      <ul>
        <li @click="setTitle($t('common.user_info'))">
          <router-link :to="{ name: 'set-profile' }" class="text-white">
            <div class="flex items-center gap-2">
              <img src="@/static/img/icon/info-icon.svg"  alt="">
              <span class="uppercase font-semibold">{{ $t('common.user_info') }}</span>
            </div>
          </router-link>
        </li>
<!--        <li @click="setTitle($t('common.setting_account'))">-->
<!--          <router-link :to="{ name: 'set-account' }" class="text-white">-->
<!--            <cds-feather-icons type="settings" size="14"/>-->
<!--            {{ $t('common.setting_account') }}-->
<!--          </router-link>-->
<!--        </li>-->
        <li @click="setTitle($t('common.change_password'))">
          <router-link :to="{ name: 'set-password' }" class="text-white">
            <div class="flex items-center gap-2">
              <img src="@/static/img/icon/lock-icon.svg" alt="">
              <span class="uppercase font-semibold">{{ $t('common.change_password') }}</span>
            </div>
          </router-link>
        </li>
      </ul>
    </nav>
  </cds-cards>
</template>
<script>
import {computed, defineComponent} from "vue";
import {useStore} from "vuex";

export default defineComponent({
  name: 'AuthorBox',
  components: {
  },
  setup() {
    const MAX_LENGTH_NAME = 20
    const {state, dispatch} = useStore();
    const userInfo = computed(() => state.auth.userInfo);
    // const userName = computed(() => userInfo.value.fullName.length > MAX_LENGTH_NAME
    //     ? userInfo.value.fullName.substring(0, MAX_LENGTH_NAME)
    //     : userInfo.value.fullName)
    const uploading = computed(() => state.auth.uploading);
    const handleChange = (file) => {
      if (file.target.files && file.target.files.length) {
        dispatch('changeAvatar', file.target.files[0])
      }
    };
    const setTitle = (title) => {
      dispatch('setTitleHeader', title)
    }
    return {
      userInfo,
      handleChange,
      uploading,
      setTitle,
      // userName,
      MAX_LENGTH_NAME,
    }
  }
});
</script>

<style lang="scss">

.btn-upload {
  transform: translateY(-33px);
  border: 1px solid white;
}

.author-info {
  .ant-card-body {
    background: red;
  }
  border-bottom: none !important;
}

</style>
